html,
body {
  height: 100%;
}

body {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #eef5f9;
}

.App {
	height: 100%;
}